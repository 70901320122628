import constants from '@/constants';
import axios from 'axios';

const API_BASE_URL = constants.BaseApiURL
const request = (methodeRequest, endpoint, formData, type = 'json', typeResponse = '') => {
  console.log("==>> ",process.env.API_BASE_URL)
  const token = localStorage.getItem(constants.KeyLocStoreLoginSession) !== undefined ? localStorage.getItem(constants.KeyLocStoreLoginSession) : '';
  let headers = { Authorization: `Bearer ${token}` }
  if (type == "formData") {
    headers['Content-Type'] = 'multipart/form-data'
  }
  return axios({
    method: methodeRequest,
    url: API_BASE_URL + endpoint,
    headers: headers,
    responseType: typeResponse, // important
    data: formData,
  }).then(response => response.data).catch((error) => {
    if(error.code == 'ERR_NETWORK'){
      return {data: null, err: "Opps.!! ada kendala pada jaringan"}
    }
    if (error.response != undefined && error.response.status === 401) {
      window.location = '/logout';
      return true;
    }
    return {data: error.response?.data.data,err : error.response?.data.error};
  });
}
const logout = (methodeRequest, endpoint, formData, type = 'json', typeResponse = '') => {
  const token = (localStorage.getItem('token') !== undefined) ? localStorage.getItem('token') : '';
  if (type === 'json') {
    return axios({
      method: methodeRequest,
      url: API_BASE_URL + endpoint,
      headers: { Authorization: `Bearer ${token}` },
      responseType: typeResponse, // important
      data: formData,
    }).then(response => response.data);
  } if (type === 'formData') {
    return axios.post(API_BASE_URL + endpoint,
      formData,
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      }).then(response => response.data);
  }
  return true;
}
const http = {
  request,
  logout
}

export default http