export default {
    BaseApiURL : "https://api.successmandiri.co.id/",
    // BaseApiURL : "http://localhost:4000/",
    Logo : "@/assets/images/logo.png",
    KeyLocStoreLoginSession :"login-session",
    KeyLocStoreForgetPass :"tmp-89h230j@k",
    KeyLocStoreRegister :"tmp-9ij208h@k",
    IconOptions: [
        {
          value: 'long-pants',
          label: 'long-pants',
          icon: 'long-pants',
        },
        {
          value: 'disconnect-outlined',
          label: 'disconnect-outlined',
          icon: 'disconnect-outlined',
        },
      ],
      ConditionOptions:[
        {
            value: '>',
            label: '>',
          },
          {
            value: '>=',
            label: '>=',
          },
          {
            value: '<=',
            label: '<=',
          },
          {
            value: '<',
            label: '<',
          },
          {
            value: '=',
            label: '=',
          },
      ],
      UnitTypeOptions: [
        {
          value: 'PCS',
          label: '/PCS (Pieces)',
        },
        {
          value: 'KG',
          label: '/KG (Kilogram)',
        },
        {
          value: 'DUS',
          label: '/DUS (dus)',
        },
        {
          value: 'KAR',
          label: '/KAR (Karung)',
        },
        {
          value: 'MTR',
          label: '/MTR (Meter)',
        },
        {
          value: 'LTR',
          label: 'LTR (liter)',
        },
      ],
}